import React, { Component } from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';

class Contact extends Component {
    state = {
        date: new Date(),
      }
    
    onChange = date => this.setState({ date })
    
    render() {
        return (
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
            <Calendar
              onChange={this.onChange}
              value={this.state.date}
              calendarType="US"
            />
          </div>
        );
    }
}

export default Contact;