import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import './About.css'

export const About = () => {
    return (
        <Container className="about-container">
        <Row className="justify-content-center">
            <Col xs={12} md={7}>
                <Row className="about-name justify-content-center">About Alex Sheperd</Row>
                <Row className="about-me-description">
                    I grew up outside of St. Louis, Missouri. My path to my career in Computer Science was far from straight forward. 
                    In 2010, I graduated from Missouri State Univeristy, focusing my studies in Psychology and Geronotlogy.
                </Row>
            </Col>
            <Col xs={12} md={5}><Image className="about-image" src="./alex-small.jpg" rounded /></Col>
            
        </Row>
        
    </Container>
        
    )
};