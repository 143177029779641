import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons';

export const Header = () => {
    return (
        <Navbar className="color-nav-header justify-content-md-center" collapseOnSelect expand="lg" >
            <Navbar.Brand className="header-brand"><FontAwesomeIcon className="fa-lg" icon={faUserAstronaut} />{' '}
                    sheperd<sub>.dev</sub></Navbar.Brand>
            </Navbar>

    )
};