import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Navigation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faBookOpen, faMeteor, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

export const Navigation = () => {
  return (
    <Navbar className="color-nav justify-content-center" >
          <Nav className='  justify-content-space-around'>
            <Nav.Link className="nav-link-style" href="/about"><FontAwesomeIcon className="fa-2x" icon={faMeteor} /><br/>About</Nav.Link>
            <Nav.Link className="nav-link-style" href="/community"><FontAwesomeIcon className="fa-2x" icon={faProjectDiagram} /><br/>Projects</Nav.Link>
            <Nav.Link className="nav-link-style" href="/learn"><FontAwesomeIcon className="fa-2x" icon={faBookOpen} /><br/>Learn</Nav.Link>
            <Nav.Link className="nav-link-style" href="/contact"><FontAwesomeIcon className="fa-2x" icon={faEnvelopeOpenText} /><br/>Contact</Nav.Link>
          </Nav>
      </Navbar>
  );
};
