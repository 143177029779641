import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './components/Home';
import Learn from './components/Learn/Learn';
import { Donate } from './components/Donate/Donate';
import { Community } from './components/Community/Community';
import { About } from './components/About/About';
import Contact from './components/Contact/Contact';

const routing = (
    <Router>
      <div>
        <Route path="/" component={App} />
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/learn" component={Learn} />
        <Route path="/donate" component={Donate} />
        <Route path="/community" component={Community} />
        <Route path="/contact" component={Contact} />
      </div>
    </Router>
  )


  ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
