import React, { Component } from 'react';
import './Home.css';

class Home extends Component {
    render() {
        return (
            <div className="container">
                <div className="center">
                    sheperd.dev
                </div>
                <div className="center">
                    coming soon
                </div>
            </div>
        );
    }
}

export default Home;